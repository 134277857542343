.vision-container {
    /* min-height: 65vh; */
    min-height: 64vh;
        width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vision-content {
    /* padding: 2rem; */
        max-width: 800px;
        /* margin: 0 20px; */
}

.vision-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.vision-text {
    font-size: 1.12rem;
        line-height: 1.5;
    text-align: justify;
    /* padding-top: 2vh; */
        color: rgb(255, 255, 255);
        margin: 0;
}

.vision-highlight {
    color: #2563eb;
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {

    .vision-text {
        font-size: 0.9rem;
    }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {

    .vision-text {
        /* padding-top: 2vh; */
        /* font-size: 1.1rem; */
        font-size: 1rem;
    }

}
/* Mobile specific adjustments */
@media (max-width: 768px) {

    .vision-container {
        min-height: 70vh;
        padding: 0;
        display: flex;
        /* justify-content: flex-start;
        align-items: flex-start; */

        justify-content: center;
            align-items: center;

        /* height: 100%; */
        background-color: rgba(255, 255, 255, 0);
        position: relative;
        overflow: hidden;

        
    }

    .vision-content {
        width: 100%;

        padding: 0px;
        /* padding-top: 15%; */
        /* padding-top: 9vh; */
        text-align: left;
        height: 100%;
        overflow-y: auto;
        position: relative;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .vision-content::-webkit-scrollbar {
        display: none;
    }

    .vision-text {

        max-width: 100%;
        font-size: 14px;
        line-height: 1.4;
        text-align: justify;
        color: rgb(255, 255, 255);
        padding: 0;
        margin: 0;
    }

    .vision-highlight {
        color: #0000FF;
    }

}