/* Hide scrollbar but maintain functionality */
* {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

*::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}
.small-talk-center {
    min-height: 67vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-talk-container {
    display: flex;
    align-items: flex-start;
    gap: 9rem;
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
}
.intro-text {
    flex: 0 0 33%;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    position: sticky;
    top: 2rem;
    height: fit-content;
}

.quotes-grid {
    flex: 0 0 56%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-height: calc(63vh - 4rem);
    overflow-y: auto;
    padding-right: 0.5rem;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent; /* For Firefox */
}

.quotes-grid::-webkit-scrollbar {
    width: 4px; /* Thin scrollbar */
    display: block; /* Show scrollbar */
}

.quotes-grid::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
}

.quotes-grid::-webkit-scrollbar-track {
    background: transparent;
}

.quote-item {
    text-align: left;
    position: relative;
    background: rgba(255, 255, 255, 0);
    border-radius: 8px;
    margin-bottom: 1rem;
}

.quote-mark {
    font-size: 1rem;
    color: #e0e0e0;
    font-family: Georgia, serif;
    line-height: 1;
    margin-bottom: -0.5rem;
    padding-left: 50px;
}

.quote-text {
    width: 290px;
    font-size: 0.9rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: rgb(255, 255, 255);
}

.quote-author {
    font-size: 0.8rem;
        color: rgb(255, 255, 255);
    /* font-weight: 600; */
    font-style: italic;
}

.small-talk-para {
    color: white;
    text-align: justify;
    margin: 0;
    padding: 0;
    /* padding-top: 5%; */
    margin-top:-1.5%;
        font-size: 1.1rem;
}

@media (min-width: 1025px) {
    .quotes-grid {
        scrollbar-width: thin;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
    }

    .quotes-grid::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, Opera */
    }
}

/* Mobile Responsive Styles */
@media (max-width: 1024px) {
    .small-talk-container {
            flex-direction: column;
            gap: 2rem;
            padding: 1rem;
            height: auto;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    
        .intro-text {
            flex: 0 0 30%;
            position: static;
        }
    
        .quotes-grid {
            flex: 0 0 25%;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.75rem;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            scrollbar-width: none; /* Hide scrollbar on mobile */
            -ms-overflow-style: none;
        }
        
        .quote-item {
            /* padding: 0.75rem; */
            width: 100%;
            /* background: rgba(255, 255, 255, 0.1); */
        }
        
        .quote-mark {
            padding-left: 0;
            margin-bottom: 0.5rem;
        }
        
        .quote-text {
            width: 100%;
            font-size: 0.6rem;
    }

    /* Change scrollbar color to white for mobile */
    .small-talk-container::-webkit-scrollbar {
        width: 8px; /* Adjust width as needed */
    }

    .small-talk-container::-webkit-scrollbar-thumb {
        background-color: white; /* Set scrollbar color to white */
        border-radius: 10px; /* Optional: add rounded corners */
    }

    .small-talk-container::-webkit-scrollbar-track {
        background: transparent; /* Optional: set track color */
    }

    .quotes-grid::-webkit-scrollbar {
        display: none; /* Hide scrollbar on mobile */
    }
}

@media (max-width: 768px) {
    .small-talk-center{
        overflow-y: auto;
        webkit-overflow-scrolling: touch;
            /* For smooth scrolling on iOS */
    }
         .small-talk-container::-webkit-scrollbar {
             width: 8px;
             /* Adjust width as needed */
         }
    
         .small-talk-container::-webkit-scrollbar-thumb {
             background-color: white;
             /* Set scrollbar color to white */
             border-radius: 10px;
             /* Optional: add rounded corners */
         }
    
         .small-talk-container::-webkit-scrollbar-track {
             background: transparent;
             /* Optional: set track color */
         }
    .small-talk-container {
        /* min-height: 100vh; */
        max-height: 180vh;
        height: 100rem;
        /* height: auto; */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        flex-direction: column;
            padding: 0px;
            gap: 15px;
           
    }

    .quotes-grid {
        height: auto;
        overflow: visible;
        
    }

    .small-talk-para {
            font-size: 0.8rem;
        }
      


    .intro-text {
        flex: none;
        position: static;
        margin-bottom: 20px;
    }

    .quotes-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* Two columns */
        gap: 8px;
        /* Gap between quotes */
        padding: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        /* max-height:365px; */
        /* max-height: 375px; */
        max-height: 35vh;
        height: 100%;
        /* padding-bottom: 100px; */
        /* padding-bottom: 10rem; */
    }

    .quote-item {
        padding: 0;
        background: transparent;
        border-radius: 0;
        /* min-height: 80px; */
            min-height: 6.5rem;
        /* Ensure consistent height */
        display: flex;
        flex-direction: column;
        /* overflow-y: auto; */
        -webkit-overflow-scrolling: touch;
    }

    .quote-mark {
        padding-left: 0;
        margin-bottom: 4px;
    }

    .quote-mark svg {
        width: 16px;
        height: 12px;
    }

    .quote-text {
        font-size: 12px;
        line-height: 1.3;
        margin: 0;
        color: rgb(255, 255, 255);
    }

    /* Remove author styling since it's now part of quote-text */
    .quote-author {
        /* display: none; */
    }
}

@media (max-width: 480px) {

    .small-talk-container {
            padding: 0rem;
                overflow-y: auto;
                        padding-top: 2rem;
    }
    
                                                                .quotes-grid {
                                                                    /* gap: 0.5rem; */
                                                                    gap: 0.7rem;
                                                                }
                                
                                                                .quote-item {
                                                                    /* padding: 0.25rem; */
                                                                    padding: 0rem;
    }
}
 .small-talk-container::-webkit-scrollbar {
     width: 8px;
     /* Adjust width as needed */
 }

 .small-talk-container::-webkit-scrollbar-thumb {
     background-color: white;
     /* Set scrollbar color to white */
     border-radius: 10px;
     /* Optional: add rounded corners */
 }

 .small-talk-container::-webkit-scrollbar-track {
     background: transparent;
     /* Optional: set track color */
 }
.small-talk-center {
    min-height: 67vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

