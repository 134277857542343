/* Brands.css */

/* .brands-container { */
/* padding: 20px; */
/* padding-top: 21px; */
/* padding-top: 1%; */
/* max-width: 1200px; */
/* margin: 0 auto; */
/* margin: 0; */
/* height: 90%; */
/* position: relative; */
/* overflow: visible; */
/* overflow-x: scroll; */
/* } */



body {
    -webkit-font-smoothing: antialiased;
    /* For WebKit browsers */
    -moz-osx-font-smoothing: grayscale;
    /* For Firefox on macOS */
}
.element {
    transform: translateZ(0);
    /* Forces hardware acceleration */
}
.brands-container {
/* padding-top: 1%; */
    margin: 0;
    /* width: 90%; */
    width: 98%;
        height:38rem;
    position: relative;
    overflow: visible;
}
/* For screens taller than 800px (typically 14" and larger laptops) */
@media screen and (min-height: 691px) {
    .brands-container {
        /* height: auto; */
        overflow-y: visible;
    }

    .letters-grid {
        min-height: 87vh;
    }
}

/* For screens shorter than 800px (typically 13" and smaller laptops) */
@media screen and (max-height: 690px) {
    .brands-container {
        height: 87vh;
        /* height: 67vh; */
        overflow-y: auto;
        padding-right: 10px; /* Add padding for scrollbar */
    }

    .letters-grid {
        min-height: 100%;
        padding-bottom: 2rem;
    }

    /* Customize scrollbar for webkit browsers */
    .brands-container::-webkit-scrollbar {
        width: 6px;
        background: transparent;
    }

    .brands-container::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 3px;
    }

    .brands-container::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.5);
    }

    /* Customize scrollbar for Firefox */
    .brands-container {
        scrollbar-width: thin;
        scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
    }

    .brands-container:hover {
        scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
    }
}

/* Remove the scrollbar hiding for larger screens */
@media screen and (min-height: 800px) {
    .brands-container {
        /* height: auto; */
        overflow-y: visible;
    }
}

/* Rest of your existing CSS remains the same */
.letters-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1%;
    justify-content: space-between;
}

/* Custom scrollbar styles for Chrome, Edge, and Safari */
@media screen and (max-height: 800px) {
    .brands-container::-webkit-scrollbar {
        width: 8px;
    }

    .brands-container::-webkit-scrollbar-track {
        background: transparent;
    }

    .brands-container::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
    }

    .brands-container::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.8);
    }

    /* Custom scrollbar styles for Firefox */
    .brands-container {
        scrollbar-width: thin;
        scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
    }

    .brands-container:hover {
        scrollbar-color: rgba(255, 255, 255, 0.8) transparent;
    }
}

/* For larger screens, hide scrollbar completely */
@media screen and (min-height: 800px) {
    .brands-container {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .brands-container::-webkit-scrollbar {
        display: none;
    }
}
.letters-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 60px; */
    gap: 1%;
    justify-content: space-between;
    /* height: 100%; */
    /* min-height: 87vh; */
    /* min-height: 94vh; */
    height: 76vh;
    /* max-height: 85vh; */
    
        /* Make the content taller than the container */
        padding-bottom: 2rem;
        /* Add some padding at the bottom */
            padding-top: 3.4vh;
}

.letter-column {
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 10px;
    height: 100%;
        width: 50%;
}

.letter-row {
    position: relative;
    min-height: 30px;
}

.letter-item {
    font-size: 18px;
    /* color: #ffffff75; */
    color: rgba(255, 255, 255, 0.46);
    cursor: pointer;
    transition: all 0.3s ease;
    /* padding: 5px; */
    padding: 0px;
    min-width: 20px;
    z-index: 2;
}

.letter-item:hover,
.letter-item.selected {
    color: #ffffff;
    font-weight: bold;
}

.brands-list {
    /* position: fixed; */
    /* Use fixed positioning to place it relative to the viewport */
    position: absolute;
    left: 60%;
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    gap: 8px;
    z-index: 10;
    padding: 5px 15px;
    border-radius: 4px;
    width: max-content;
    max-width: 400px;
    /* background-color: white; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

/* Position variants */
.brands-list.position-center {
    top: 50%;
    transform: translateY(-57%);
}

.brands-list.position-top {
    top: 0;
    transform: translateY(0);
}

.brands-list.position-bottom {
    bottom: 0;
    transform: translateY(0%);
}

.brand-item {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    white-space: nowrap;
    display: block;
}

.brand-item:hover {
    text-decoration: underline;
}

/* Animation for brands appearing */
.brands-list {
    animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .letters-grid {
        gap: 30px;
    }

    .brands-list {
        max-height: calc(100vh - 400px);
    }
}

.brands-list.multi-column {
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    /* gap: 8px; */
    gap: 0px;
    overflow: visible;
    padding: 5px 15px;
    height: auto;
    max-height: none;
    background-color: rgba(255, 255, 255, 0);
}

@media (max-width:1200px) {
    .brands-list.multi-column {
        grid-template-columns: repeat(1, minmax(120px, 1fr));
        overflow-y: scroll;
        /* background-color: red; */
    }

    .brands-list {
        grid-template-columns: repeat(1, minmax(120px, 1fr));
        overflow-y: scroll;
        /* background-color: pink; */
    }
}

/* Add these mobile-specific styles */
@media (max-width: 768px) {
    .brand-item {
        /* color: #ffffff75; */
    color: rgba(255, 255, 255, 0.46);
    }

    .letter-brands-group.selected .brand-item {
        color: #ffffff;
    }

    .brands-list {
        text-align: left !important;
    }

    .brands-container.mobile {
        mask-image: linear-gradient(to bottom, transparent 0%, black 20%, black 80%, transparent 100%);
        overflow: hidden;
    }

    .letter-column {
        font-family: 'Bebas', sans-serif !important;
        font-weight: 100;
    }

    .brands-container.mobile .letters-grid {
        grid-template-columns: 128px 1fr;
        gap: 10px;
        overflow: hidden;
        height: 100vh;
        -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 95%, transparent 100%);
        mask-image: linear-gradient(to bottom, transparent 0%, black 5%,
            black 95% transparent 100%);
    }

    .brands-container.mobile .letter-column {
        overflow-y: auto;
                overflow-x: hidden;
        /* padding: 25vh 12px;
        gap: 110px; */

        padding: 10vh 12px;
            /* Reduced from 25vh to 10vh */
            gap: 100px;
            /* Reduced gap to compensate */
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .brands-container.mobile .letter-column::-webkit-scrollbar {
        display: none;
    }

    .brands-container.mobile .brands-list {
        
        position: sticky;
        top: 50%;
        transform: translateY(-50%);
    }

    .brands-container.mobile .letter-brands-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .brands-container.mobile .brand-item {
        padding: 8px 0;
        font-size: 16px;
        color: #ffffff;
        text-decoration: none;
        width: 100%;
    }

    .brands-container.mobile .letter-item {
        font-size: 128px;
        font-weight: 100;
        /* color: #ffffff75; */
    color: rgba(255, 255, 255, 0.46);
    }

    .brands-container.mobile .letter-item.selected {
        color: #ffffff;
    }

    .brands-container.mobile .letter-brands-group .brand-item {
        /* color: #ffffff75; */
    color: rgba(255, 255, 255, 0.46);
    }

    .brands-container.mobile .letter-brands-group.selected .brand-item {
        color: #ffffff;
    }

    .letter-row {
        padding: 10px 0;
        -webkit-tap-highlight-color: transparent;
        touch-action: manipulation;
    }

    .letter-item {
        min-height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-user-select: none;
        user-select: none;
    }
}

.letter {
    font-size: 2rem;
    /* Adjust size as needed */
    transition: opacity 0.3s ease;
}

.letter:not(.focused) {
    opacity: 0.5;
    /* Faded effect */
}

.parent-container {
    display: flex;
    align-items: center;  
    min-height: 65vh;    
    /* min-height: 85vh; */
        /* Changed from 65vh to 85vh */
    width: 100vw;
        height: 134%;
    /* min-height: 58vh; */
    /* width: 90vw; */
/* margin: auto 0; */
    /* Add this to help with vertical centering */

        /* display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            height: -webkit-fill-available; */
            

}


/* Custom scrollbar styles for Firefox */
.brands-container {
    scrollbar-width: none; /* Make scrollbar thin */
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Thumb color and track color */
}

/* For Firefox hover effect */
.brands-container:hover {
    scrollbar-color: rgba(255, 255, 255, 0.8) transparent; /* Thumb color on hover */
}

/* Style for single column layout */
.single-column {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

/* Style for multi-column layout */
.multi-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px; /* Adjust gap as needed */
}

/* Additional styles for brand items */
.brand-item {
  padding: 5px; /* Adjust padding as needed */
  text-decoration: none; /* Remove underline */

}

.invisible-letter-row {
  height: 50px; /* Adjust height as needed */
  visibility: hidden; /* Make the element invisible */
}











