:root {
  --inactive-color: #ffffff62;
  --active-color: #ffffff;
}




@font-face {
  font-family: 'Avenir Roman';
  src: url('./fonts/AvenirLTStd-Roman\ -Desc.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Grovana Medium';
  src: url('./fonts/Grovana\ Medium\ -\ Menu.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src: url('./fonts/TT\ Commons\ Medium\ -\ NOFILTR.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

/* .App {
  cursor: url(/public/Logo-white.png) auto !important;
} */
 * {
   cursor: none !important;
 }
 .App{
  cursor: none !important;
 }


.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
  background-color: transparent;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.root-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.tabs-container {
  font-family: "Avenir", sans-serif !important;
  display: flex;
  flex-direction: column;
  gap: 2px;
  /* margin-top: 14px; */
  margin-top: 7px;
  padding-left: 23px;
  z-index: 5;
}

.top-line,
.bottom-line {
  display: flex;
  justify-content: flex-start;
  gap: 11px;
}

.tab-item {
  font-family: "Grovana Medium", sans-serif !important;
  font-size: 33px;
  transition: all 0.3s ease;
  text-align: left;
  color: white;
}

.tab-item.active {
  /* font-weight: 700; */
  color: #ffffff;
}

.blur-background {
  backdrop-filter: blur(10px);
}

.content-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  height: 100%;
}

.tab-content {
  font-size: 24px;
  font-weight: 300;
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
}

.brand-name {
  font-family: "TT Commons", sans-serif;
  font-size: 13.8vw;
  font-weight: medium;
  text-align: center;
  z-index: 10;
  letter-spacing: 0.01em;
  color: var(--active-color);
  transition: color 0.3s ease;
  line-height: 11.6rem;
}

/* When blur-background is active (meaning a tab is active), make brand-name inactive */
.blur-background .brand-name {
  color: var(--inactive-color);
}

/* On hover, always show active color */
.blur-background .brand-name:hover {
  color: var(--active-color);
}

.tab-item.active .content-container {
  color: var(--active-color);
}

.brand-name.active {
  color: var(--active-color);
}

.brand-name:hover {
  color: var(--active-color);
}

.tab-item:hover {
  color: #ffffff;
}

.tab-item.active {
  color: #ffffff;
}

.max-w-full::-webkit-scrollbar {
  display: none;
}

.expertise-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.expertise-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.expertise-content::-webkit-scrollbar {
  display: none;
}

.blur-background .tab-item {
  color: var(--inactive-color);
}

.blur-background .tab-item.active {
  color: var(--active-color);
}

.blur-background {
  color: var(--inactive-color);
}

.blur-background .brand-name .tab-item:hover:not(.active) {
  color: var(--inactive-color) !important;
}

.archives-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.mobile-logo-container {
  display: none;
}



.arrow-icon {
  height: auto;
  /* width: 2rem; */
  width: 1.4rem;
  /* width: 25%; */
  padding-bottom: 2%;
}

/* Mobile Devices (up to 425px) */
/* @media (max-width: 425px) {
  .brand-name {
    font-family: "TT Commons", sans-serif;
    font-size: 11vw;
    line-height: 5rem;
  }

  .app-container {
    padding: 4px 17px;
    justify-content: space-between;
    height: 100vh;
  }

  .tabs-container {
    padding-left: 0;
    gap: 0;
    z-index: 5;
    font-family: "Grovana Medium", sans-serif !important;
  }

  .top-line,
  .bottom-line {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }

  .tab-item {
    font-size: 18px;
    padding: 4px 0;
    line-height: 1.2;
    font-weight: bold;
  }

  .brand-name {
    font-weight: bold;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .archives-label {
    gap: 2px;
    font-family: "Grovana Medium", sans-serif !important;
  }

  .arrow-icon {
    width: 25px;
    height: 25px;
    margin-left: 0px;
  }

  .mobile-logo-container {
    display: block;
    position: fixed;
    top: 14px;
    right: 20px;
    z-index: 1000;
  }

  .mobile-logo {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
  }

  .blur-background .mobile-logo {
    filter: invert(80%);
  }
} */



/* Mobile Devices (up to 425px) */
@media (max-width: 500px) {
  .brand-name {
    font-family: "TT Commons", sans-serif;
    font-size: 8.6vw;
    /* Responsive size across all small, medium, and large mobiles */
    line-height: 3.5rem;
    letter-spacing: 0.25rem;
    font-weight: medium;
    color: var(--active-color);
    display: none;
  }

  .app-container {
    padding: 4px 17px;
    justify-content: space-between;
    height: 100vh;
  }

  .tabs-container {
    padding-left: 0;
    gap: 0;
    z-index: 5;
    font-family: "Grovana Medium", sans-serif !important;
  }

  .top-line,
  .bottom-line {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }

  .tab-item {
    font-size: 18px;
    padding: 4px 0;
    line-height: 1.2;
    font-weight: 400;
    color: white;
  }

  .archives-label {
    gap: 2px;
    font-family: "Grovana Medium", sans-serif !important;
  }

  .arrow-icon {
    width: 25px;
    height: 25px;
    margin-left: 0px;
  }

  .mobile-logo-container {
    display: block;
    position: fixed;
    top: 14px;
    right: 20px;
    z-index: 1000;
  }

  .mobile-logo {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
  }

  .blur-background .mobile-logo {
    /* filter: invert(100%); */
  }
}

/* Modern iPhones */
@media screen and (min-width: 375px) and (max-width: 393px) {
  .brand-name {
    font-family: "TT Commons", sans-serif !important;
    font-weight: 600;
    font-size: 8.6vw;
    line-height: 3.2rem;
    letter-spacing: 0.1rem;
    display: none;
  }
}

@media screen and (min-width: 394px) and (max-width: 428px) {
  .brand-name {
    font-family: "TT Commons", sans-serif !important;
    font-weight: 600;
    font-size: 9vw;
    line-height: 4.4rem;
    letter-spacing: 0.25rem;
    display: none;
  }
}

/* Tablet Devices (426px to 768px) */
@media (min-width: 426px) and (max-width: 768px) {
  .brand-name {
    font-family: "TT Commons", sans-serif !important;
    font-weight: 600;
    font-size: 11vw;
    line-height: 4.6rem;
    letter-spacing: 0.25rem;
  }
}

/* Portrait Tablets and iPads (max-width: 768px) */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .brand-name {
    font-family: "TT Commons", sans-serif;
    font-size: 8.6vw;
    /* Same as mobile */
    line-height: 4rem;
    /* Same as mobile */
    letter-spacing: 0.25rem;
    font-weight: 600;
    color: var(--active-color);
  }

  .app-container {
    padding: 4px 17px;
    /* Same as mobile */
    justify-content: space-between;
    height: 100vh;
  }

  .tabs-container {
    padding-left: 0;
    gap: 0;
    z-index: 5;
    font-family: "Grovana Medium", sans-serif !important;
  }

  .top-line,
  .bottom-line {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }

  .tab-item {
    font-size: 18px;
    /* Same as mobile */
    padding: 4px 0;
    line-height: 1.2;
    font-weight: 400;
    color: white;
  }

  .archives-label {
    gap: 2px;
    font-family: "Grovana Medium", sans-serif !important;
  }

  .arrow-icon {
    width: 1rem;
    /* width: 20%; */
    /* Same as mobile */
    height: auto;
    margin-left: 0px;
  }

  .mobile-logo-container {
    display: block;
    position: fixed;
    top: 14px;
    right: 20px;
    z-index: 1000;
  }

  .mobile-logo {
    width: 24px;
    /* Same as mobile */
    height: 24px;
    transition: all 0.3s ease;
  }

  .blur-background .mobile-logo {
    /* filter: invert(100%); */
  }
}

/* Landscape Tablets (Android Tablets and iPads) */
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .brand-name {
    font-size: 11.8vw;
    /* Same as laptop */
    line-height: 12.6rem;
    /* Same as laptop */
  }

  .app-container {
    /* Add any necessary adjustments for landscape tablets */
    padding: 4px 17px;
    justify-content: space-between;
    height: 100vh;
  }

  .tabs-container {
    padding-left: 23px;
    /* Match laptop style */
    gap: 2px;
    font-family: "Avenir", sans-serif !important;
  }

  .tab-item {
    font-size: 25px;
    /* Match laptop style */
    font-weight: 400;
    color: var(--inactive-color);
  }

  .tab-item.active {
    color: var(--active-color);
  }
}

/* Laptop Devices (769px to 1440px) */
@media (min-width: 769px) and (max-width: 1440px) {
  .brand-name {
    /* font-size: 11.8vw; */
    /* font-size: 13vw; */
    font-size: 14.2vw;
    /* line-height: 11.6rem; */
    line-height: 9rem;

  }
}

/* 4K Devices (1441px and above) */
@media (min-width: 1441px) {
  .brand-name {
    /* font-size: 12.8vw; */
    font-size: 13.8vw;
    /* line-height: 12rem; */
    line-height: 11rem;
  }
}

/* For dark mode */
@media (prefers-color-scheme: dark) {
  .mobile-logo {
    /* filter: invert(100%); */
  }

  .blur-background .mobile-logo {
    /* filter: invert(100%); */
  }
}

/* MacBook Air 13" (2560 x 1600) and similar */
@media screen and (min-width: 2560px) and (max-width: 2880px) {
  .brand-name {
    font-size: 13.8vw;
    line-height: 13rem;
    letter-spacing: 0.01em;
  }
}

/* MacBook Pro 14" (3024 x 1964) */
@media screen and (min-width: 3024px) and (max-width: 3455px) {
  .brand-name {
    font-size: 13.8vw;
    line-height: 14rem;
    letter-spacing: 0.01em;
  }
}

/* MacBook Pro 16" (3456 x 2234) and larger */
@media screen and (min-width: 3456px) {
  .brand-name {
    font-size: 13.8vw;
    line-height: 15rem;
    letter-spacing: 0.01em;
  }
}

.custom-cursor {
  width: 100%;
  height: 100%;
  filter: brightness(0);
}

.custom-cursor.rotating {
  animation: rotateCursor 2s linear infinite;
}

@keyframes rotateCursor {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Only show custom cursor on desktop/laptop devices */
@media (min-width: 1024px) {
  * {
    cursor: none !important;
  }

  .custom-cursor {
    width: 100%;
    height: 100%;
    filter: brightness(0) invert(1);
  }
}

/* Hide custom cursor on mobile/tablet devices */
@media (max-width: 1023px) {
  .custom-cursor {
    display: none !important;
  }
  
  * {
    cursor: auto !important;
  }

  .hide-cursor-border {
    display: none;
    border: none;
  }
}