.inside-jokes-container {
    min-height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* gap: 6rem; */
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    justify-content: center;
    align-items: center;
}
 .inside-jokes-center {
        align-items: center;
            display: flex;
            justify-content: center;
            min-height: 55vh;
} 
.section-container {
    display: flex;
    gap: 0.5rem;
    /* gap: 2rem; */
    /* align-items: flex-start; */
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: column;
    width: 100%;
    height: 19rem;
}

.frame-section {
    flex: 0 1;
}

.frame {
    /* border: 10px solid #8b4513; */
    /* Brown frame color */
    padding: 0px;
    /* background: #fff; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 190px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.frame-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.text-section {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
}
.a{
    cursor: pointer !important;
}
.clickable-image {
    cursor: pointer;
    /* Change cursor to pointer */
}
.section-text {
    color: rgb(255, 255, 255);
    /* font-size: 2.14rem; */
    font-size: 1.5rem;
    /* font-weight: bold; */
    line-height: 1.5;
    width: 100%;
}
/* Styles for laptop and desktop */
@media (min-width: 1024px) {
    .frame-section {
        margin: 0 12rem;
        /* Apply margin for larger screens */
    }
}

/* Responsive design */
@media (max-width: 1024px) {
    .inside-jokes-container {
        flex-direction: row;
        /* padding: 2rem; */
        padding: 12vh;
        justify-content: center;
        align-items: center;
    }
 
}

@media (max-width: 768px) {
    .inside-jokes-center{
        display: flex;
            /* Ensure it's a flex container */
            justify-content: center;
            /* Center horizontally */
            align-items: center;
            /* Center vertically */
            height: 75vh;
            /* Ensure it takes full height of the parent */
    }
    .inside-jokes-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        /* padding-top: 7.5vh; */
        padding-left: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        margin: 0;
        justify-content: center;
    }

    .section-container {
        flex-direction: column;
        /* gap: 10px; */
        margin: 0;
        align-items: flex-start;
        height: 100%;
    }

    .frame {
        width: 150px;
        height: 180px;
        margin: 0;
    }

    .text-section {
        width: 150px;
        padding: 0;
        /* margin-top: -200px; */
        /* margin-top: -273px; */
    }

    .section-text {
        color: #ffffff;
        /* font-size:3.5vh; */
        font-size: 2vh;
        line-height: 1.3;
        width: 100%;
        margin: 0;
        text-align: center;
        font-weight: normal;
        white-space: pre-wrap;
            /* This preserves whitespace */
    }
}

.section-link {
  display: block;
  width: 100%;
  height: 100%;
}