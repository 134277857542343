/* :root {
    --archives-bg-color: white;
    --archives-padding: 0;
    --archives-grid-gap: 1rem;
    --archives-item-border-radius: 0.5rem;
    --archives-media-hover-scale: 1.05;
    --archives-transition-duration: 0.3s;
    --tabs-container-height: 80px;
} */
:root {
    --archives-bg-color: white;
    --archives-padding: 0;
    --archives-grid-gap: 1rem;
    --archives-item-border-radius: 0.5rem;
    --archives-media-hover-scale: 1.05;
    --archives-transition-duration: 0.3s;
    --tabs-container-height: 80px;
}
.archives-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    overflow: auto;
    background-color: var(--archives-bg-color);
    scroll-padding-top: var(--tabs-container-height);
}

.archives-container {
    padding: 10px;
    padding-top: calc(var(--tabs-container-height) + 10px);
    /* max-width: 94vw; */
        max-width: 98vw;
    margin: 0 auto;
    height: auto;
    width: 100%;
    position: relative;
    overflow-y: auto;
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.archives-container::-webkit-scrollbar {
    display: none;
}

.my-masonry-grid {
    display: flex;
    margin-left: -16px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 16px;
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* .archive-item {
    margin-bottom: 16px;
    border-radius: 16px;
    overflow: hidden;
    break-inside: avoid;
    background: #fff;
    transition: transform 0.2s ease;
} */
.archive-item {
    margin-bottom: 16px;
    border-radius: 16px;
    overflow: hidden;
    break-inside: avoid;
    background: #fff;
    transition: transform 0.2s ease;
    height: fit-content;
}
/* .archive-media {
    width: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.3s ease;
    filter: grayscale(100%);
} */


.archive-media {
    width: 100%;
    height: auto;
    display: block;
    transition: all 0.3s ease;
    filter: grayscale(100%);
    max-height: 80vh;
    object-fit: cover;
}

.archive-item:hover .archive-media {
    filter: grayscale(0%);
    transform: scale(var(--archives-media-hover-scale));
}


/* Remove grayscale on hover */
/* .archive-item:hover .archive-media {
    filter: grayscale(0%);
    transform: scale(var(--archives-media-hover-scale));
} */

/* Remove the fixed aspect ratios */
.video-item .archive-media,
.image-item .archive-media {
    width: 100%;
    height: auto;
}

/* Add play icon styles */
.video-item {
    position: relative;
}

.video-item::before {
    /* content: "▶"; */
    /* Unicode play symbol */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    z-index: 2;
    transition: opacity 0.3s ease;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

/* Hide play icon on hover */
.video-item:hover::before {
    opacity: 0;
}

/* Optional: you might want to darken the video slightly to make the play icon more visible */
.video-item .archive-media {
    filter: grayscale(100%) brightness(0.9);
}

.video-item:hover .archive-media {
    filter: grayscale(0%) brightness(1);
}

/* Media Queries */
@media (max-width: 768px) {
    :root {
        --tabs-container-height: 250px;
    }

    .archives-container {
        max-width: 94vw;
        padding-top: calc(var(--tabs-container-height) + 8px);
        padding-left: 8px;
        padding-right: 8px;
        overflow-x: hidden;
    }

    .my-masonry-grid {
        margin-left: -8px;
    }

    .my-masonry-grid_column {
        padding-left: 8px;
    }

    .archive-item {
        margin-bottom: 8px;
        border-radius: 12px;
        height: auto !important; /* Override any fixed heights */
    }

    .archive-media {
        width: 100%;
        height: auto;
        max-height: none; /* Remove max-height constraint */
        aspect-ratio: auto; /* Let the image determine its aspect ratio */
        object-fit: contain; /* Ensure the entire image is visible */
    }

    .video-item .archive-media,
    .image-item .archive-media {
        width: 100%;
        height: auto;
        aspect-ratio: auto;
        object-fit: contain;
    }

    /* Modify hover effects for better mobile experience */
    .archive-item:hover .archive-media {
        transform: none;
    }

    .archive-item:active .archive-media {
        filter: grayscale(0%);
    }

    .video-item:active::before {
        opacity: 0;
    }
}

/* Additional breakpoint for very small devices */
@media (max-width: 480px) {
    .archives-container {
        max-width: 100vw;
        padding-left: 4px;
        padding-right: 4px;
    }

    .my-masonry-grid {
        margin-left: -4px;
    }

    .my-masonry-grid_column {
        padding-left: 4px;
    }

    .archive-item {
        margin-bottom: 4px;
        border-radius: 8px;
    }
}

@media (min-width: 1024px) {
    .my-masonry-grid {
        margin-left: -15px;
    }

    .my-masonry-grid_column {
        padding-left: 16px;
    }
}
